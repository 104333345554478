.manageindextemp-main {
  height: 100vh;
  font-family: inherit;
}

.manageindextemp-content {
  height: 87vh;
  margin: 2%;
  border-radius: 8px;
}

.maintemplateDiv {
  height: 96% !important;
  width: 97% !important;
}

.maintemplateDiv .leftdivTemplate {
  float: left;
  width: 45%;
}

.maintemplateDiv .rightdivTemplate {
  display: none;
  float: right;
  width: 50%;
}

.indexTemplatemargin {
  margin: 0.5rem !important;
  font-weight: 750 !important;
  font-size: large;
}

.controlLableindextemp {
  margin-right: 0.5rem !important;
}

.customindexTemplateSelect {
  height: 93% !important;
}

.updatedeletebtnindextemplate {
  line-height: 1.2 !important;
}

.addfieldDiv {
  margin-top: 1rem;
}

.marginforpopupField {
  margin: 0.8rem;
}
