.browseDocumentsync {
  width: 95% !important;
  margin: 1% auto 0;
  font-family: inherit !important;
  height: 87vh !important;
  /* background-color: #fafafa; */
}
.browseButton {
  margin-bottom: 1%;
}
.cJYPLF {
  border: 1px solid #999;
  font-size: 13px;
  height: 80vh;
  /* border: 6px solid #d0d0d0; */
  border-radius: 5px;
}
.tabulator {
  background-color: #fafafa;
  border-radius: 6px;
}

.tabulator-col-content {
  font-size: 12px;
}

.jZxzTm svg {
  fill: gray !important;
  stroke: gray !important;
}

.jZxzTm {
  margin-right: 7px !important;
}

.dYiyJz {
  border-left: 4px solid gray !important;
}

.cDmiNZ {
  border-left: 4px solid gray !important;
}

.gutter {
  background: rgba(255, 255, 255, 0.05);
  flex-shrink: 0;
}

.gutter-horizontal {
  cursor: ew-resize;
  background-image: url("../Images/ellipsis.jpg?v=1.0") !important;
  background-repeat: no-repeat !important;
  background-size: 20px, 18px !important;
  background-position: center !important;
  width: 18px !important;
}

.gutter-vertical {
  cursor: ns-resize;
}
.comp {
  display: inline-block;
}
.wrap {
  display: flex;
}

.renderToolTipBrowseButton {
  float: right;
}

.renameFileToolTip {
  display: none;
}

.fileTypeImage {
  height: 16px;
  width: 16px;
  cursor: default;
}
