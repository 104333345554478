.ocr-main {
    height: 100vh;
    font-family: inherit;
  }
  
  .ocr-content {
    height: 87vh;
    margin: 2%;
    border-radius: 8px;
  }

.mainOcrDiv {
    height: 96% !important;
    width: 97% !important;
  }
  
  .mainOcrDiv .leftdivTemplate {
    float: left;
    width: 60% !important;
  }
  
  .updatedeletebtnocr {
    line-height: 1.2 !important;
  }

  .customindexTemplateSelect {
    height: 93% !important;
  }
  