.manageusers-main {
  font-family: inherit;
}

.manageusers-content {
  margin: 2%;
  width: 98%;
  border-radius: 8px;
}

.Add-UserButton {
  float: right;
  margin: 1rem;
}

.Delete-UserButton {
  float: right;
  margin: 1rem;
}

#listUsers {
  clear: both;
  margin-top: 10px;
}

.client-controls {
  display: flex;
  justify-content: flex-end; /* Align all elements to the right */
  align-items: center;       /* Align elements vertically in the center */
  gap: 1rem;                 /* Space between select and buttons */
  width: 100%;   
}

.client-select {
  width: 250px !important;         /* Set a fixed width for the dropdown */
}