.maindiv {
  font-family: inherit !important;
  border-radius: 20px;
  margin: 2%;
  height: 97% !important;
  width: 97% !important;
}

.maindiv .myRepoandPrivilege {
  float: left;
  width: 55%;
  margin-right: 15px;
}

.maindiv .myaccountContentRight {
  float: left;
  width: 40%;
}

.errorMessage {
  margin-top: 5%;
  color: #9e0203;
  display: flex;
  justify-content: center;
}

.successMessage {
  margin-top: 5%;
  color: green;
  display: flex;
  justify-content: center;
}

.login-legend {
  display: block;
  width: inherit;
  max-width: inherit;
  padding: 5px;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

.my-fieldset {
  min-width: 0;
  margin: 0;
  padding: 15px;
  border: 1px solid lightgrey;
  border-radius: 20px;
}

.PasswordText {
  margin-left: 3%;
  font-size: small;
  color: gray;
}
