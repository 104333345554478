.managerepo-main {
  height: 100vh;
  font-family: inherit;
}

.managerepo-content {
  height: 87vh;
  margin: 2%;
  border-radius: 8px;
}
.addnew-content {
  margin-left: 0.5%;
}

.tabulator-header-filter input {
  border-radius: 4px !important;
  border: 0px !important;
}

.updatedeletebuttons {
  line-height: 1.2 !important;
}

.addNewButton {
  margin-top: 2vh;
}

.controllabel {
  margin: 0.5rem !important;
  font-weight: 750 !important;
  font-size: large;
}

.errorLabel {
  font-size: small;
  font-weight: 750;
  margin-left: 0.5%;
  color: red;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.managerepo-content .header-container .client-dropdown {
  width: 200px !important;
  margin-left: auto !important;
}

.client-selection-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.client-dropdown {
  margin-right: 10px;
  flex-grow: 1;
}

.add-client-button {
  white-space: nowrap;
}

.add-client-form {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.add-client-form .form-control {
  margin-right: 10px;
}

.add-client-form .btn {
  margin-right: 5px;
}

.green-plus-sign {
  color: #4CAF50; /* A nice shade of green */
  font-size: 1.2em; /* Adjust size as needed */
}
