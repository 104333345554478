@media all and (min-width: 480px) {
  .SearchDocumentSync {
    width: 95%;
    margin: 1% auto 0;
  }
}
.SearchDocumentSync {
  font-family: inherit !important;
  height: 85vh;
}
#header {
  background-color: #fff;
  height: 6vh !important;
  border-radius: 4px;
  background: #9e0203 !important;
  width: 99.5% !important;
  margin: 0 auto;
  min-height: 40px;
}
#header img {
  float: left;
  /* width: 315px; */
  margin: 1px 0 0 2px;
  height: 95%;
}
#header #login {
  float: right;
  margin: 20px 10px 0 0;
}
#header #login span {
  margin-right: 10px;
}
#header button {
  float: right;
  padding: 0.7%;
  font-size: small;
  color: #e9efee;
}
#header #login input[type="text"],
#header #login input[type="password"] {
  width: 300px;
  margin-right: 10px;
}

.SearchDocumentSync .left {
  float: left;
  width: 26%;
  height: 82vh;
}
.searchResetbutton {
  width: 48%;
  margin-right: 2%;
}
.SearchDocumentSync .right {
  float: right;
  width: 72%;
  height: 82vh;
  display: none;
}
.input-box {
  padding: 3.2px !important;
  height: 33px !important;
}

.custom-select {
  padding: 3.2px !important;
  height: 33px !important;
}
.indexTemplateselect {
  padding: 3.2px !important;
  height: 33px !important;
}

.SearchDocumentSync #searchResults table {
  width: 100%;
}

#mainPageDiv {
  height: 100vh;
}
.SearchDocumentSync #searchResults thead {
  display: table;
  width: calc(100% - 17px);
  table-layout: fixed;
}
.SearchDocumentSync #searchResults tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.SearchDocumentSync #searchResults tbody {
  display: block;
  overflow: auto;
  table-layout: fixed;
  max-height: 600px;
}

.renderToolTipButton {
  display: none;
}

.renameFileToolTipButton {
  float: right;
  display: none;
}

.right-top button {
  margin: 1px;
}

.SearchDocumentSync .left #searchControls .floating-label {
  position: absolute;
  font-size: 10px;
  color: lightslategrey;
  margin-top: -4.6em;
  /* margin-left: 5px; */
  transition: 0.5s ease all;
  pointer-events: none;
  display: none;
}

.static-label {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: black !important;
}

.left #searchControls input:not(:placeholder-shown) ~ span {
  font-size: 11px !important;
  font-weight: 500 !important;
  color: black !important;
  /* left: 18vw !important; */
  display: block !important;
}

input::placeholder {
  font-size: 15px !important;
  color: lightslategrey !important;
}

input[type="text"]::placeholder {
  font-size: 15px !important;
  color: #9e0203 !important;
}

.form-group {
  width: 90%;
  margin-bottom: 16px !important;
}

.date {
  margin-bottom: 2px;
}

.headerIconImages {
  height: 80px !important;
  width: 80px !important;
  margin-left: 2% !important;
}

a {
  cursor: pointer;
}

.tabulator-table {
  border-bottom: 1px solid #999;
}

.header-content {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.tabulator .tabulator-header {
  border-bottom: 0px !important;
}

.fileTypeImage {
  height: 16px;
  width: 16px;
  cursor: default;
}

.tabulator .tabulator-tableHolder {
  overflow: hidden !important;
}
